<template>
  <component :is="layout" class="content">
    <slot />
    <call-me-back-button v-if="cbrVisible"></call-me-back-button>
    <call-me-back-modal></call-me-back-modal>

    <basket-button v-if="basketVisible"></basket-button>
    <basket-modal v-if="basketVisible"></basket-modal>
    <cookie-consent-modal v-if="ownCookieSolution"></cookie-consent-modal>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import { CUSTOM_APPEARANCE, UI_CONFIGS } from "@/utils/const";
import { isBrand } from "@/utils/util";

const defaultLayout = "AppLayoutDefault";

const importedLayouts = {};

export default {
  name: "AppLayout",
  methods: { isBrand },
  components: {
    CallMeBackModal: () => import("@/components/CallMeBackModal.vue"),
    CallMeBackButton: () => import("@/components/CallMeBackButton.vue"),
    BasketButton: () => import("@/components/basket/BasketButton.vue"),
    BasketModal: () => import("@/components/basket/BasketModal.vue"),
    CookieConsentModal: () => import("@/components/CookieConsentModal.vue"),
  },
  computed: {
    CUSTOM_APPEARANCE: () => CUSTOM_APPEARANCE,
    ...mapGetters({
      loggedIn: "session/isReady",
      hasActivePurchase: "purchase/hasActivePurchase",
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    cbrVisible() {
      return this.$route.meta.mortgage
        ? this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST_MORTGAGE) ===
            "true"
        : this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST) === "true";
    },
    ownCookieSolution() {
      return (
        this.autoloadConfigValue(UI_CONFIGS.OWN_COOKIE_SOLUTION) === "true"
      );
    },
    basketVisible() {
      return (
        this.loggedIn &&
        this.hasActivePurchase &&
        !this.$route.fullPath.includes("/checkout")
      );
    },
    layout() {
      const layout = this.$route.meta.layout || defaultLayout;

      // Avoid unnecessary re-render of layouts (dynamic component in the template)
      if (!importedLayouts[layout]) {
        importedLayouts[layout] = () => import(`@/layouts/${layout}.vue`);
      }
      return importedLayouts[layout];
    },
  },
};
</script>
